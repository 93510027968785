import React from "react";
import "./App.css";
import Salt from "./Salt";

class App extends React.Component {
  now: Date = new Date();
  constructor(props: any) {
    super(props);
    this.now = new Date();
  }

  updateTime = () => {
    setTimeout(this.updateTime, 1000);
    this.now = new Date();
    console.log(this.now);
  };

  componentDidMount = () => {
    this.updateTime();
  };

  shouldComponentUpdate = () => true;

  render = () => {
    console.log("now", this.now);
    return <Salt now={this.now} />;
  };
}
export default App;
