import React from "react";
import "./App.css";
import dayjs from "dayjs";

type Year = "2021" | "2022";

function App(props: { now: Date }) {
  const startDate = new Date("2021-02-25T00:00:00.00-06:00");
  const data = {
    "2022": {
      startOfOutage: new Date("2022-03-31T08:00:00-06:00"),
      endOfOutage: undefined,
    },
    "2021": {
      startOfOutage: new Date("2021-03-23T08:00:00-06:00"),
      endOfOutage: new Date("2021-04-01T16:00:00-06:00"),
    },
  };

  const diffInHours = (start: Date | dayjs.Dayjs, end: Date | dayjs.Dayjs) =>
    dayjs(end).diff(start) / 1000 / 60 / 60;

  const uptimePercent = (hoursOfService: number) =>
    ((hoursOfService -
      Object.keys(data)
        .map((year) =>
          diffInHours(
            data[year as Year].startOfOutage,
            data[year as Year].endOfOutage
              ? (data[year as Year].endOfOutage as unknown as Date)
              : props.now
          )
        )
        .reduce((acc, n) => acc + n, 0)) /
      hoursOfService) *
    100;

  const shipStuckTime = 147.6;

  const downtimeInHours = (year: Year) =>
    diffInHours(data[year].startOfOutage, data[year].endOfOutage ?? new Date());

  const shipdiffMessage = (year: Year) =>
    downtimeInHours(year) - shipStuckTime > 0
      ? `that's ${(downtimeInHours(year) - shipStuckTime).toFixed(2)} hours`
      : `in ${(0 - (downtimeInHours(year) - shipStuckTime)).toFixed(
          2
        )} hours that'll be`;

  // const timeToPercent = (hoursOfService: number, targetPercent: number) => {
  //   const requiredHours =
  //     hoursOfService - (targetPercent / 100) * hoursOfService;
  //   const hitDay = dayjs(data.startOfOutage).add(requiredHours, "hour");
  //   return diffInHours(new Date(), hitDay);
  // };
  return (
    <div className="App">
      <div></div>
      <div className="container">
        <h2>March 31, 2022 - (now) Incident</h2>
        <p>
          Oh, hello there! Me again. Another March/April, another FRII WiFrii
          outage...
        </p>
        <p>
          The service {data["2022"].endOfOutage ? "was" : "has been"} down for{" "}
          {downtimeInHours("2022").toFixed(3)} hours, {shipdiffMessage("2022")}{" "}
          longer than{" "}
          <a href="https://istheshipstillstuck.com/">
            that ship was stuck in the Suez Canal
          </a>
          .
        </p>
        <p>
          As is apparently yearly tradition, FRII's networking stack has
          apparently imploded. This time due to:
          <p>
            <i>
              We had one of our core routers start to fail on us, while our
              primary uplink to the internet started having connection issues.
            </i>
          </p>
        </p>
        <p>
          Except, this time it appears to even affect colocation connections, so
          we're doubly down.
        </p>
        <p>
          The good news is:{" "}
          <a href="https://frii.com">FRII has a status page</a>. The weird news
          is: they've entirely disabled selecting any text on their status
          page...
        </p>
        <p>
          Update 7:38 AM 4/5: FRII made a post last night at 7:40 PM stating
          that some customers now have connectivity. They also stated that "For
          those of you who do have connectivity, you may be experiencing a slow
          connection or a "flapping" connection." It's now twelve hours since
          that announcement and our WiFrii link is sitting at a nice{" "}
          <pre>93.8% packet loss</pre>
        </p>
        <h2>March 23, 2021 - April 1, 2021 Incident</h2>
        <p>
          WiFrii has {data["2021"].endOfOutage ? "" : "not been"} declared fixed
          (for us).
        </p>
        <p>
          The service {data["2021"].endOfOutage ? "was" : "has been"} down for{" "}
          {downtimeInHours("2021").toFixed(3)} hours, {shipdiffMessage("2021")}{" "}
          longer than{" "}
          <a href="https://istheshipstillstuck.com/">
            that ship was stuck in the Suez Canal
          </a>
          .
        </p>
        <p>
          <span className="faux-link">Here</span> is where I'd link to FRII's
          status page... if they had one.
        </p>
        <p>
          FRII's WiFrii service has been up{" "}
          {uptimePercent(diffInHours(startDate, props.now))}% of the time we've
          been using it.
        </p>
      </div>
    </div>
  );
}

export default App;
